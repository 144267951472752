import React, { useState } from "react";

interface AccordionProps {
  index: number;
  question: string;
  answer: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ index, question, answer }) => {
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);

  const toggleAccordion = () => {
    setActiveAccordion((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="bg-white px-3">
      <h5 id={`accordion-flush-heading-${index}`}>
        <button
          type="button"
          className={`flex items-center justify-between w-full py-5 font-medium rtl:text-right text-black border-b border-gray-200 gap-3`}
          data-accordion-target={`#accordion-flush-body-${index}`}
          aria-expanded={activeAccordion === index}
          aria-controls={`accordion-flush-body-${index}`}
          onClick={toggleAccordion}
        >
          <span className="text-start">{question}</span>
          <svg
            data-accordion-icon
            className={`w-3 h-3 rotate-180 shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </button>
      </h5>
      <div
        id={`accordion-flush-body-${index}`}
        className={`py-5  border-gray-200 dark:border-gray-700 ${
          activeAccordion === index ? "" : "hidden"
        }`}
        aria-labelledby={`accordion-flush-heading-${index}`}
      >
        {answer}
      </div>
    </div>
  );
};

const FAQAccordian: React.FC = () => {
  return (
    <div id="accordion-container" className="">
      <Accordion
        index={0}
        question="What are amino acids?"
        answer={
          <div>
            <p className="text">
              Amino acids are the fundamental building blocks of proteins. Amino
              acids play essential roles in biological processes in cell,
              including human cells. Their different and distinct properties
              influence their behavior and function. <a href={`${window.location.origin}/blog/what-are-amino-acids`} className="underline">Read More...</a>
            </p>
          </div>
        }
      />
    </div>
  );
};

export default FAQAccordian;
