import AboutUs from "../assets/PepSynBanner.webp";
import WhoWeAre from "../assets/aboutUs.webp";
import { InputField } from "../Components/InputField";
import { CustomButton } from "../Components/CustomButton";
import Request from "../assets/requestQuote.webp";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { useRef, useState } from "react";
import { ErrorSpan } from "../Components/ErrorSpan";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

export type Query = {
  name: string;
  email: string;
  subject: string;
  message: string;
  recaptcha: string;
};

export const AboutUsPage = () => {
  const [query, setQuery] = useState<Query>({
    name: "",
    email: "",
    subject: "",
    message: "",
    recaptcha: "",
  });
  const [errors, setErrors] = useState<any>({
    name: "",
    email: "",
    subject: "",
    message: "",
    recaptcha: "",
  });
  const [captchaErr, setCaptchaErr] = useState<string>("");
  const recaptchaRef = useRef<any>();
  const formRef = useRef<any>();
  const sitekey: any = process.env.REACT_APP_CAPTCHA_KEY;
  const requiredErr = "This field is Mandatory";
  const handleBlur = (
    event: React.FocusEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    let errorMessage = "";

    switch (name) {
      case "name":
        errorMessage = value === "" ? requiredErr : "";
        break;
      case "email":
        errorMessage = value === "" ? requiredErr : "";
        break;
      case "subject":
        errorMessage = value === "" ? requiredErr : "";
        break;
      case "message":
        errorMessage = value === "" ? requiredErr : "";
        break;
      default:
        break;
    }

    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const validateData = (formData: FormData) => {
    const tempErr = {
      name: formData.get('name') === "" ? requiredErr :  "",
      email: formData.get('email') === "" ? requiredErr :  "",
      subject: formData.get('subject') === "" ? requiredErr :"",
      message: formData.get('message') === "" ? requiredErr :  "",
    }

    setErrors(tempErr);
    const errorCheck = Object.values(tempErr).some(error => error !== "");

    return errorCheck;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const hasErrors = validateData(formData);
    if (!query.recaptcha) {
      setCaptchaErr("Captcha is required");
      return;
    }
    try {
      if(!hasErrors){
      await axios({
        method: "post",
        url: "/api/query",
        data: query,
      });
      formRef.current?.reset();
      recaptchaRef.current?.reset();
      toast.success("Form submitted");
      }
    } catch (e: any) {
      toast.error("Error submitting the form");
    }
  };

  const handleRecaptcha = (value: any) => {
    if (!value) setCaptchaErr("Captcha is required");
    setQuery((prev) => ({
      ...prev,
      recaptcha: value,
    }));
    setCaptchaErr("");
  };

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setQuery((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };


  return (
    <>
      <div
        style={{ backgroundImage: `url(${Request})` }}
        className="xl:px-32 lg:px-20 md:px-10 px-5 py-14 "
      >
        <h1 className="text-3xl font-bold text-white">About Us</h1>
        <p className="text-lg md:text-xl font-medium text-white">
          Welcome to Peptide Machines, Inc.
        </p>
      </div>
      <div className="">
        <div className="relative">
          <div className="grid md:grid-cols-2 grid-cols-1  place-items-center my-7 xl:px-32 lg:px-20 md:px-10 px-5">
            <div className="flex flex-col gap-3 md:gap-7 justify-between items-start">
              <h3 className="font-bold text-xl md:text-2xl lg:text-3xl">
                Welcome to Peptide Machines - Your Trusted Peptide Synthesizer Company 
              </h3>
              <p className="font-medium text-base  md:text-[1.05rem] xl:text-lg">
                Company was stablished by engineers and peptide chemists who
                have been in peptide and organic synthesis and instrumentation
                since 1980's.
              </p>
              <p className=" font-medium text-base md:text-[1.05rem] xl:text-lg xl:mb-6">
                We are now providing all your peptide synthesis needs from amino acids, resins and reagents to top quality custom peptide synthesis and soon we will be adding new synthesizers to our product list. Keep check equipment’s and Reagent’s back for the latest details!
              </p>
            </div>

            <img
              src={WhoWeAre}
              alt=""
              className="order-first md:order-last mt-6 md:mt-0 md:ms-5 hidden md:block"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center md:my-7 xl:px-32 lg:px-20 md:px-10 px-5">
        <div className="">
          <img
            src={AboutUs}
            alt="about-us"
            className="md:ms-12 w-[75%] hidden md:block"
          ></img>
        </div>
        <div className="md:pe-14  md:col-span-1">
          <h3 className="font-bold text-xl md:text-2xl lg:text-3xl mb-3">
            What We Do
          </h3>
          <p className="font-medium text-base">
            We are now providing all your peptide synthesis needs from amino
            acids, resins and reagents to top quality custom peptide synthesis
            and soon we will be adding new synthesizers to our product list.
          </p>
          <p className="font-medium text-base text-justify">
            Our instruments are designed by experienced engineers and peptide
            chemists who know peptide synthesis.
          </p>
          <p className="font-medium   text-base text-justify">
            {" "}
            Keep checking back for the latest details!
          </p>
        </div>
      </div>
      <div className="xl:px-32 lg:px-20 md:px-10 px-5 mt-7">
        <div className="grid md:grid-cols-3 grid-cols-1 gap-4 my-5">
          <div className="bg-primary bg-opacity-[0.15] p-3 gap-3 flex flex-col ">
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="text-xl me-3 text-primary"
              />
              <span className="font-semibold">Peptide Machines, Inc.</span>
            </div>
            <div>
              <span>3002 Weather Way Louisville, KY 40220</span>
            </div>
          </div>
          <div className="bg-primary bg-opacity-[0.15] p-3  gap-3 flex flex-col ">
            <div className="flex items-center ">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-xl me-3 text-primary"
              />
              <span className="font-semibold">Have any question?</span>
            </div>
            <div>
              <a href="mailto:sales@peptidemachines.com">sales@peptidemachines.com</a>
            </div>
          </div>
          <div className="bg-primary bg-opacity-[0.15] p-3  gap-3 flex flex-col ">
            <div className="flex items-center ">
              <FontAwesomeIcon
                icon={faComment}
                className="text-xl me-3 text-primary"
              />
              <span className="font-semibold">Call us</span>
            </div>
            <div>
              <span>502-767-5870</span>
            </div>
          </div>
        </div>
        <h3 className="font-semibold text-xl mb-4 mt-4">Write a message</h3>
        <form
          ref={formRef}
          className="bg-primary bg-opacity-[0.15] sm:p-7 p-5 mb-7"
          onSubmit={handleSubmit}
        >
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
            <div className="flex flex-col relative">
              <InputField
                onBlur={handleBlur}
                type="text"
                placeholder="Name *"
                fieldName="name"
                onChange={handleChange}
                className={`${errors.name ? " border-[1px] border-red-500" : ""}`}
              />
              {errors.name && <ErrorSpan error={errors.name} />}
            </div>

            <div className="flex flex-col relative">
              <InputField
                onBlur={handleBlur}
                type="email"
                placeholder="Email *"
                fieldName="email"
                onChange={handleChange}
                className={`${errors.email ? " border-[1px] border-red-500" : ""}`}
              />
              {errors.email && <ErrorSpan error={errors.email} />}
            </div>

            <div className="flex flex-col relative">
              <InputField
                onBlur={handleBlur}
                type="text"
                placeholder="Subject *"
                fieldName="subject"
                onChange={handleChange}
                className={`${errors.subject ? " border-[1px] border-red-500" : ""}`}
              />
              {errors.subject && <ErrorSpan error={errors.subject} />}
            </div>
            <div className="md:col-span-3 flex flex-col relative">
              <textarea
                onBlur={handleBlur}
                placeholder="Message *"
                rows={3}
                name="message"
                className={`${errors.name ? " border-[1px] border-red-500" : ""} w-full  p-3 mt-2`}
                onChange={handleChange}
                
              />
              {errors.message && <ErrorSpan error={errors.message} />}
            </div>
            <div className="flex flex-col relative">
              <ReCAPTCHA
                sitekey={sitekey}
                className="mt-2 captcha"
                onChange={handleRecaptcha}
                ref={recaptchaRef}
              />
              {captchaErr && <ErrorSpan error={captchaErr} />}
            </div>
          </div>
          <CustomButton className="mt-6" type="submit" title="Send Message" />
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default AboutUsPage;
